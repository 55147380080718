<template>
  <div>
    <div v-if="loader" class="d-flex justify-content-center mb-3">
      <b-button variant="flat-primary" disabled class="mr-1">
        <b-spinner small />
        Téléchargement du RT...
      </b-button>
    </div>
    <div v-else>
      <b-card id="call_header">
        <b-link id="back" :to="{ name: 'home' }">«</b-link>
        <b-row>
          <b-col cols="10" id="title">
            <b-card-title class="mb-1">
              <h1>{{ call.detail.titre }}</h1>
            </b-card-title>
            <b-card-text>RT du {{ call.detail.nicedate }}</b-card-text>
            <b-badge pill variant="light-dark" v-if="call.detail.tag">{{
              call.detail.tag.name
            }}</b-badge>
            <b-badge pill  v-if="call.detail.hubspot.deal.hasOwnProperty('id')"><b-link target="_blank" :href="'https://app.hubspot.com/contacts/5018614/deal/' + call.detail.hubspot.deal.id + '/'"><img src="@/assets/images/logo/hubspot.svg" width="15" /> {{call.detail.hubspot.deal.properties.dealname}}</b-link></b-badge>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="relief-primary"
              class="btn-icon float-right"
              right
              @click="choose_library()"
            >
              <feather-icon icon="BookmarkIcon" /> Ajouter à la librairie
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col cols="6">
            <div
              v-if="!call.detail.hasOwnProperty('media')"
              class="d-flex justify-content-center mb-3 align-items-center"
              id="loader"
            >
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <video controls id="call" v-if="'media' in call.detail" v-on:timeupdate="currentTime()">
              <source :src="call.detail.media" type="video/mp4" />
            </video>
            <b-badge variant="light-info" v-if="'analytics' in call.detail && 'tp_crpf' in call.detail.analytics"><feather-icon icon="MicIcon" size="12" /> {{call.detail.analytics.tp_crpf}}%</b-badge>
            <b-badge variant="light-info" v-if="'analytics' in call.detail && 'duration' in call.detail.analytics"><feather-icon icon="ClockIcon" size="12" /> {{call.detail.analytics.duration}}min</b-badge>
            <!--
            <b-button variant="relief-primary" v-if="snippet.start==false" v-on:click="start_snippet"><feather-icon icon="CropIcon" size="16" /> Créer un snippet à partir de {{this.currentTimeMedia}}s</b-button>
            <div v-if="snippet.start==true">
              <p>Le Snippet démarre à {{snippet.starttime}}s</p>
              <b-button variant="relief-primary" v-on:click="stop_snippet"><feather-icon icon="CropIcon" size="16" /> Fin du snippet à {{this.currentTimeMedia}}s</b-button>
            </div>
            <b-form-group v-if="snippet.start==true && snippet.stoptime > snippet.starttime">
              <label for="email">Commentaire</label>
              <b-form-textarea
                id="textarea"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <br />
              <b-button variant="flat-primary">
                <span class="align-middle">Annuler</span>
              </b-button>
              <b-button variant="primary">
                <span class="align-middle">Créer le snippet</span>
              </b-button>
            </b-form-group>
            -->
          </b-col>
          <b-col cols="6">
            <br />
            <comments
              v-if="call.comments.length>0"
              :initialcomments="call.comments"
              :id_call="$route.params.id_call"
              :key="update_comments"
              v-on:refreshcomments="refreshcomments"
            />
            <b-card bg-variant="light" >
              <b-row>
                <b-col cols="5">
                  
                </b-col>
                <b-col cols="3">
                  <center>Snippet</center>
                </b-col>
                <b-col cols="4">
                  
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <b-form-textarea
                    id="textarea"
                    v-model="comment"
                    placeholder="Commentaire..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="3">
                  <b-button variant="primary" v-on:click="start_snippet" block><span v-if="!snippet.start">Début</span><span v-else>De {{snippet.starttime}}s</span></b-button>
                  <br />
                  <b-button variant="primary" v-on:click="stop_snippet" block><span v-if="snippet.start==false || snippet.stoptime <= snippet.starttime">Fin</span><span v-else>à {{snippet.stoptime}}s</span></b-button>
                </b-col>
                <b-col cols="4">
                  <v-select
                    v-model="selected_users"
                    multiple
                    placeholder="Notifier un utilisateur"
                    label="email"
                    :options="users.list"
                    class="primary"
                    append-to-body
                    :calculate-position="withPopper"
                  />

                  <br />

                  <v-select
                    v-model="selected_teams"
                    multiple
                    placeholder="Notifier une équipe"
                    label="name"
                    :options="teams.list"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                </b-col>
              </b-row>
              <br />
              <b-button block v-on:click="add_comment" variant="primary">
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                    class="align-middle"
                  />
                  <span class="align-middle">Ajouter</span>
                </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        ref="modal-select-library"
        id="modal-select-library"
        cancel-variant="outline-secondary"
        centered
        title="Ajouter à une librairie"
        :hide-footer="true"
      >
        <select-library
          v-if="folders.list.length > 0"
          :p_folders="folders.list"
          :p_id_call="$route.params.id_call"
          v-on:select_folder="select_folder"
        />
        <br />
        <b-form-textarea
          id="textarea"
          v-model="comment_library"
          placeholder="Commentaire..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <br />
        <b-button block v-on:click="add_to_library" variant="primary">
          <span class="align-middle">Ajouter à la librairie</span>
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";
import { createPopper } from '@popperjs/core'
import Ripple from "vue-ripple-directive";
import SelectLibrary from "@core/components/libraries/SelectLibrary.vue";
import Comments from "@/@core/components/chat/Comments.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardText,
  BCardFooter,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BBadge,
    vSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardFooter,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BModal,
    BForm,
    BFormGroup,
    SelectLibrary,
    Comments,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentTimeMedia: 0,
      snippet: {
        start: false,
        starttime: 0,
        stoptime: 0,
        comment: ""
      },
      loader: true,
      call: {
        detail: {},
        comments: [],
      },
      selected_folder: false,
      comment: "",
      comment_library: "",
      selected_users: [],
      selected_teams: [],
      temp_comment: null,
      folders: {
        list: [],
      },
      users: {
        list: [],
      },
      teams: {
        list: [],
      },
      update_comments: 0,
    };
  },
  methods: {
    start_snippet() {
      this.snippet.start = true
      this.snippet.starttime = this.currentTime()
    },
    stop_snippet() {
      if (this.currentTime() <= this.snippet.starttime) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "La fin est avant le début",
            icon: "EditIcon",
            variant: "warning"
          },
        }
      );
      } else {
        this.snippet.stoptime = this.currentTime()
      }
    },
    refreshcomments() {
      this.$http
        .post(
          $themeConfig.app.restEndPoint +
            "/call/" +
            this.$route.params.id_call +
            "/comment/get"
        )
        .then((response) => {
          this.$set(this.call, "comments", response.data);
          this.update_comments++;
        });
    },
    select_folder(folder) {
      this.selected_folder = folder;
    },
    add_to_library() {
      if (!this.selected_folder) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aucune librairie sélectionnée",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      } else {
        this.$http
          .post(
            $themeConfig.app.restEndPoint +
              "/call/" +
              this.$route.params.id_call +
              "/library/associate",
            {
              folder: this.selected_folder,
              comment: this.comment_library,
              id_user: this.user(),
            }
          )
          .then((response) => {
            if (response.data.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "RT ajouté à la librairie",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Déjà dans cette librairie",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.close_library();
            this.selected_folder = false;
            this.comment_library = "";
          });
      }
    },
    user() {
      var user = localStorage.getItem("user");
      if (user !== null) {
        user = JSON.parse(user);
        return user["id_user"];
      } else {
        this.$router.push({ name: "login" });
      }
    },
    close_library() {
      this.get_call();
      this.$refs["modal-select-library"].hide();
    },

    add_comment() {
      if (this.comment != "") {
        this.$http
          .post(
            $themeConfig.app.restEndPoint +
              "/call/" +
              this.$route.params.id_call +
              "/comment/add",
            {
              id_parent: 0,
              time: this.currentTime(),
              comment: this.comment,
              id_user: this.user(),
              snippet: this.snippet,
              notify: {
                users: this.selected_users,
                teams: this.selected_teams,
              },
            }
          )
          .then((response) => {
            this.selected_users = [];
            this.$set(this.call, "comments", response.data);
            this.update_comments++;
            this.comment = "";
          });
      }
    },
    nice_time(time) {
      var minute = Math.floor(time / 60);
      var second = time - minute * 60;
      return minute + "min" + second;
    },
    choose_library() {
      this.$refs["modal-select-library"].show();
    },
    currentTime() {
      var call = document.getElementById("call");
      this.currentTimeMedia = Math.round(call.currentTime)
      return this.currentTimeMedia;
    },
    goto(time) {
      var call = document.getElementById("call");
      call.currentTime = time;
      call.play();
    },
    getInstance(art) {
      this.art = art;
      this.art.seek = 100;
    },
    get_call() {
      this.$http
        .post(
          $themeConfig.app.restEndPoint + "/call/" + this.$route.params.id_call
        )
        .then((response) => {
          this.$set(this.call, "detail", response.data.detail);
          this.$set(this.call, "comments", response.data.comments);
          this.$set(this.users, "list", response.data.users);
          this.$set(this.teams, "list", response.data.teams);
          this.loader = false;
        });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    }
  },
  mounted() {
    this.get_call();
    this.$http
      .post($themeConfig.app.restEndPoint + "/library/list")
      .then((response) => {
        this.$set(this.folders, "list", response.data);
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#call {
  width: 100%;
}

#loader {
  height: 100%;
}

.notify {
  font-size: 10px;
}

#call_header {
  position: relative;
}
#back {
  position: absolute;
  height: 100%;
  width: 40px;
  background: #7367f0;
  color: white;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 30px;
  line-height: 135px;
  z-index: 10000;
}
#title {
  padding-left: 60px;
}
.goto {
  cursor: pointer;
}

.vs__dropdown-toggle {
  background: white !important;
}

#textarea {
  height: 96px !important;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
