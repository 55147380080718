<template>
  <div>
  <div id="wrapper_comments">
    <b-card v-for="(comment, index) in comments.list" :key="comment.index" class="chat">
      <b-row>
        <b-col cols="9">
          <p>
            <span class="user"
              >{{ comment.prenom }} {{ comment.nom }}
              <span v-if="comment.time > 0 && comment.endtime==null">
                <b-badge
                  class="goto"
                  v-on:click="goto(comment.time)"
                  variant="light-secondary"
                  >à {{ nice_time(comment.time) }}</b-badge
                >
              </span>
              <span v-if="comment.time > 0 && comment.endtime>0">
                <b-badge
                  class="goto"
                  v-on:click="goto(comment.time)"
                  variant="light-secondary"
                  >de {{ nice_time(comment.time) }} jusqu'à {{ nice_time(comment.endtime) }}</b-badge
                >
              </span>
              </span
            >
          </p>
          <p>
            <span v-if="comment.notified" class="notify">
              <span
                v-for="notified in comment.notified"
                :key="notified.index"
                class="hash"
              >
                @{{ notified.name.split("@")[0] }}
              </span>
            </span>
            <span v-html="comment.comment"></span>
          </p>
          

        </b-col>
        <b-col cols="3">
          <div class="float-right">
            <span class="date">{{ comment.date }}</span>
            <b-dropdown
              id="dropdown-grouped"
              variant="flat-primary"
              right
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="edit_comment(comment)"
                >Editer</b-dropdown-item
              >
              <b-dropdown-item v-on:click="delete_comment(comment.id_comment, index)">
                Supprimer
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      
      
      <b-card class="reply" v-for="(reply, replyindex) in comment.replies" :key="reply.index">
        <b-row>
          <b-col cols="9">
            <p>
            <span class="user"
              >{{ reply.prenom }} {{ reply.nom }}
              <span v-if="reply.time > 0">
                <b-badge
                  class="goto"
                  v-on:click="goto(reply.time)"
                  variant="light-secondary"
                  >à {{ nice_time(reply.time) }}</b-badge
                >
              </span></span
            >
          </p>
            <p>{{reply.comment}}</p>
          </b-col>
          <b-col cols="3">
            <div class="float-right">
            <span class="date">{{ comment.date }}</span>
            <b-dropdown
              variant="flat-primary"
              right
              size="sm"
              class="dropdown-icon-wrapper  dd_reply"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="edit_comment(reply)"
                >Editer</b-dropdown-item
              >
              <b-dropdown-item v-on:click="delete_reply(reply.id_comment, index, replyindex)">
                Supprimer
              </b-dropdown-item>
            </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <p>
      <!--<b-badge
        href="#"
        variant="primary"
      >
        <feather-icon
          icon="ThumbsUpIcon"
          class="mr-25"
        />
        <span>J'aime</span>
      </b-badge>
      &nbsp;-->
      <b-badge
        href="#"
        variant="info"
        v-on:click="comment.reply=!comment.reply"
      >
        <feather-icon
          icon="MessageCircleIcon"
          class="mr-25"
        />
        <span>Je réponds</span>
      </b-badge>
      </p>
      <p><b-form-input v-show="comment.reply==true" v-on:keyup.enter="replycomment(comment)" v-model="reply" /></p>
    </b-card>
    <b-modal
        ref="modal-edit-comment"
        id="modal-edit-comment"
        cancel-variant="outline-secondary"
        ok-title="Mettre à jour"
        cancel-title="Annuler"
        centered
        title="Edition commentaire"
        :hide-footer="true"
      >
        <b-form v-if="temp_comment">
          <b-form-group>
            <label for="email">Commentaire</label>
            <b-form-textarea
              id="textarea"
              v-model="temp_comment.comment"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <br />
            <b-button block v-on:click="update_comment" variant="primary">
              <span class="align-middle">Mettre à jour</span>
            </b-button>
          </b-form-group>
        </b-form>
      </b-modal>
  </div>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";

export default {
    components: {
        BSpinner,
        BBadge,
        BDropdown,
        BDropdownItem,
        BCard,
        BCardTitle,
        BCardText,
        BLink,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BModal,
        BForm,
        BFormGroup
  },
  props: {
    initialcomments: {
      type: Array,
    },
    id_call: {
        type: String
    }
  },
  data() {
      return {
        reply: "",
        temp_comment: {
          comment: "",
          id_comment: null,
          id_parent: null
        },
        copy_comment: null,
        comments: {
          list: [],
          reply: []
        }
      }
  },
  mounted() {
    this.$set(this.comments, "list", this.initialcomments)
    for (var i = 0; i < this.comments.list.length; i++) {
      this.$set(this.comments.list[i], "reply",  false)
    }
    this.scroll_bottom_wrapper()

  },

  methods: {
    goto(time) {
      var call = document.getElementById("call");
      call.currentTime = time;
      call.play();
    },
    scroll_bottom_wrapper() {
      setTimeout(()=>{
        var wrapper = document.getElementById("wrapper_comments");
        wrapper.scrollTop = wrapper.scrollHeight;
      }, 1)
    },
    user() {
      var user = localStorage.getItem("user");
      if (user !== null) {
        user = JSON.parse(user);
        return user["id_user"];
      } else {
        this.$router.push({ name: "login" });
      }
    },
    currentTime() {
      var call = document.getElementById("call");
      return call.currentTime;
    },
    replycomment (comment) {
      this.$http
        .post(
          $themeConfig.app.restEndPoint + "/call/" +
            this.$route.params.id_call +
            "/comment/add",
          {
            id_parent: comment.id_comment,
            time: this.currentTime(),
            comment: this.reply,
            id_user: this.user(),
          }
        )
        .then((response) => {
          //this.$set(this.call, "comments", response.data);
          /*comment.replies.push({
            "comment": this.reply
          })*/
          this.$emit("refreshcomments")
          this.reply = ""
        });
    },
    update_comment() {
      this.$http
        .post(
          $themeConfig.app.restEndPoint + "/call/" +
            this.id_call +
            "/comment/update/" +
            this.temp_comment.id_comment,
          this.temp_comment
        )
        .then((response) => {
          //this.copy_comment.comment =  this.temp_comment.comment
          this.$emit("refreshcomments")
          this.$refs["modal-edit-comment"].hide();
        });
    },
    edit_comment(comment) {
      this.copy_comment = comment
      this.temp_comment.comment = comment.comment;
      this.temp_comment.id_parent = comment.id_parent;
      this.temp_comment.id_comment = comment.id_comment;
      this.$refs["modal-edit-comment"].show();
    },
    delete_comment(id_comment, index) {
      this.$http
        .post(
          $themeConfig.app.restEndPoint + "/call/" +
            this.id_call +
            "/comment/delete/" +
            id_comment
        )
        .then((response) => {
          /*var comments = []
          for (var i = 0; i < this.comments.list.length; i++) {
            if (index!=i) {
              comments.push(this.comments.list[i])
            }
          }
          this.$set(this.comments, "list", comments)*/
          this.$emit("refreshcomments")
        });
    },
    delete_reply(id_comment, index, replyindex) {
      this.$http
        .post(
          $themeConfig.app.restEndPoint + "/call/" +
            this.id_call +
            "/comment/delete/" +
            id_comment
        )
        .then((response) => {
          /*var replies = []
          for (var i = 0; i < this.comments.list[index]["replies"].length; i++) {
            if (replyindex != i) {
              replies.push(this.comments.list[index]["replies"][i])
            }
          }
          this.$set(this.comments.list[index], "replies", replies)*/
          this.$emit("refreshcomments")
        });
    },
    nice_time(time) {
      var minute = Math.floor(time / 60);
      var second = time - minute * 60;
      return minute + "min" + second;
    },
  },
  
};
</script>

<style lang="scss">
.social_action {
  font-size:11px;
  display: inline-block;
  margin-right:5px;
}


.chat {
  margin-bottom:10px;
  border:1px solid #f4f4f4;
  border-left: 10px solid #7367f0;
}

.chat .card-body {
  padding:10px;
}

.chat .card-body p {
  margin-bottom:5px;
}

.chat .user {
  font-weight: bold;
  font-size:12px;
}

.chat .hash {
  font-weight:bold;
}

.reply {
  margin-bottom:10px;
  border:1px solid #f4f4f4;
  border-left: 10px solid #00cfe8;
}

.dd_reply button {
  padding-right: 3px !important;
}

.date {
  font-size: 10px;
}

#wrapper_comments {
  overflow-x: hidden;
  overflow-y:auto;
  height: calc(100vh - 700px);
}
</style>