<template>
  <div>
    <ul>
      <li
        v-for="folder in folders.list"
        :key="folder.index"
        :title="folder.name"
      >
        <b-row>
          <b-col cols="12">
            <span class="select" v-on:click="select(folder, $event)">{{ folder.name }}</span>
          </b-col>

        </b-row>

        <ul>
          <li
            v-for="_folder in folder.folders"
            :key="_folder.index"
            :title="_folder.name"
          >
            <b-row>
              <b-col cols="12">
                <span class="select" v-on:click="select(_folder, $event)">{{ _folder.name }}</span>
              </b-col>
      
            </b-row>
            <ul>
              <li
                v-for="__folder in _folder.folders"
                :key="__folder.index"
                :title="__folder.name"
              >
                <b-row>
                  <b-col cols="11">
                    <span class="select" v-on:click="select(__folder, $event)">{{ __folder.name }}</span>
                  </b-col>
                  
                </b-row>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BBadge,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  props: {
    p_folders: {
      type: Array,
    },
    p_id_call: {
      type: String
    }
  },
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BForm,
    BFormGroup,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardBody,
  },
  data() {
    return {
      folder: {
        name: "",
        parent: 0,
      },
      folders: {
        list: [],
      },
      id_call: ""
    };
  },
  mounted() {
      this.$set(this.folders, "list", this.p_folders);
      this.id_call = this.p_id_call
  },
  methods: {
    select(folder, event) {
      var selects = document.getElementsByClassName("select")
      for (var i=0; i < selects.length; i++) {
        selects[i].classList.remove("selected")
      }
      event.target.classList.add("selected")
      this.$emit("select_folder", folder)
    }
  }
};
</script>

<style scoped>
.select {
  cursor: pointer;
}

.selected {
  font-weight: bold;
  background: #7367f0;
  color:white;
  padding:2px 8px;
}
</style>